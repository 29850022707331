<template>
 <router-view :GetpermisosUser="GetpermisosUser"></router-view>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      GetpermisosUser:[]
    }
  },
  mounted() {
    // console.log(JSON.parse(localStorage.getItem('ListpermisosUser')));
    // this.GetpermisosUser=JSON.parse(localStorage.getItem('ListpermisosUser'))
  },
  components: {
  }
}
</script>
