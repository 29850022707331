import { authHeader } from './auth-header';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getlistarRolPermisosByUsuario,
};

function login(url,email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adm_email:email, password:password })
    };

    // return fetch(`/users/authenticate`, requestOptions)
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            var datos=user.user
            this.getlistarRolPermisosByUsuario(datos.id);
            // console.log(datos.id)
            // login successful if there's a jwt token in the response
            if (user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                // console.log('URL:'+process.env.VUE_APP_VUE_APP_DATABASEURL);
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}
function getlistarRolPermisosByUsuario(iduser)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Userid:iduser})
    };
    var url=process.env.VUE_APP_APIURL + '/administracion/usuarios/getListarPermisoByUsuario'
    // return fetch(`/users/authenticate`, requestOptions)
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(res => {
            let filtrado=[]
            let valory=[]
            let datdev=res;
            datdev.map( function(x,y){
                filtrado.push(x.name)
                valory.push(y)
            })
            localStorage.setItem('ListpermisosUser', JSON.stringify(filtrado));
            // console.log(filtrado)
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
